import React from "react";

const Startup = () => {
  return (
    <div>
      <p>November 1, 2024 </p>

      <p>
        I'm on a 6-hour flight to SF, so I figured this would be the best time
        to write all the personal essays I've been meaning to tackle but have
        put off due to work and school. This essay, in particular, has been
        highly requested and will probably be one of the most useful and
        pragmatic essays for anyone trying to start a startup. I want to preface
        by saying this piece is about founding a B2B SaaS startup. If you're in
        a different industry, the practical advice may be less relevant;
        however, the mindset advice should still be helpful. Even if you don’t
        plan to start a business, you might find that my advice still makes for
        good life advice. Enjoy.
      </p>

      <h2>How to Start a Startup</h2>
      <p>
        Don’t. Don’t do it. This might sound ironic, given you’re likely reading
        this article with an inclination to start a startup, but what I mean is
        really know what you are getting yourself into. YC founders work around
        60 hours a week until Series A. Personally, between being a law student
        and the Founder of Enttor, I work 7 days a week, probably ~80 hours, but
        at this point, it’s hard to count, and I've stopped counting. Basically,
        80% of my life is work/school, and there is no real concept of a weekend
        for me. I think about work from the moment I wake up to the moment I go
        to sleep. Sometimes, I'm having dinner with friends, and I'm still
        thinking about work. This summer, I skipped on a fully booked and
        planned trip to Japan, throwing away $2K because I had to teach myself
        how to be a manager. None of this is normal healthy person behavior. I
        wish I'd known what I was getting myself into before quitting my 9-5. I
        didn't realize how nice it was to turn off my brain at 5 PM and have
        someone else tell me what to do. I miss the days when I could grab
        dinner with friends after work, go out in NYC on Friday night and there
        was nothing to do on Sundays other than coffee, brunch, and a stroll
        around Central Park.
      </p>

      <h2>Ambiguity</h2>
      <p>
        Startups are ambiguous. You have to be comfortable with the unknown. If
        you’re a Type-A planner who likes to have every hour of a vacation
        scheduled, now is the time to reconsider. In the early days of a
        startup, so much is completely unknown. For example, I don't know if we
        are going to finish our $2M pre-seed raise by the end of this year, in
        January, February, or March, at which point my team is ready to go
        full-time. Therefore, I don't know if I'm going to enroll in law school
        next semester. But it's also possible that I'll run my company while
        finishing my law degree. After all, Bridgit Mendler ran a space startup
        while at Harvard Law School and being a mother! Circumstances change on
        a weekly basis and at this point I'm only planning my life 1 month ahead
        at a time. Personally, I used to be a huge planner. I was accepted into
        Harvard Law School in my junior year of college through a 2 year
        deferral program. At that point in time, I thought I had the next 6
        years of my life planned out. Graduate undergrad at 22, work in tech
        until 24, graduate law school at 27. Well, now I'm 24 and considering
        dropping out of law school. Life doesn't go how you plan. I was forced
        to learn how to be not only comfortable, but to embrace the unknown.
      </p>

      <h2>Start Small</h2>
      <p>
        Start small. Six months ago, all I had was a credit card and a name,
        Enttor. Now, Enttor is a funded startup, a fully built web application,
        and our first inbound lead. Get rid of all your limiting mindsets.
        Repeat that to yourself 3 times. Some aspiring founders think they need
        money, an idea, co-founders, or more years of work experience. I had
        none of that. The first step I took was registering a business in the
        state of New York under ZhangAI LLC. (By the way, New York is the wrong
        place to register your business. If you want VC funding, you need to
        register as a Delaware C-corp.) Do not overthink the name because it's
        easy to change the name and you'll probably rebrand in the future
        anyways so just pick any name. The second step I took was getting an
        Amex Gold business card (not the best credit card for startups, now we
        are using Mercury). At that point I didn't even have an idea, all I knew
        was that I wanted to start a business. You can do Step 1 and 2 in less
        than 1 hour today and your founder journey will have begun.
      </p>

      <p>
        Now that you’ve decided to start a business, the next step is finding
        great co-founders and a profitable problem to solve (not necessarily in
        that order). Emphasis on great co-founders. After speaking to founders
        of dissolved startups, I noticed the common theme was co-founder
        conflicts. You’ll likely work with your co-founders for the next 4-10
        years, maybe longer. The most crucial part of a startup is the founding
        team. Getting the right co-founders deserves a whole seperate essay and
        I have many thoughts on it. Look to YC or Paul Graham essays in the
        meantime.
      </p>

      <p>
        Find a profitable idea. A startup is not a non-profit. Make sure you
        find a problem that people are willing to pay you to solve. The problem
        with young founders such as college students is that they have not
        experienced the entire set of profitable problems in the world so they
        want to build a social media app, a dating app, or an app to find times
        to hangout with their friends. I'm not trying to deter you if those are
        your ideas, but just make sure there is billions of dollars to be made
        in whatever problem you are solving. And notice, I did not say you need
        an idea. There are millions of good ideas in the world but a good idea
        does not make a good business. You need to be solving a profitable
        problem that you are or will become obsessed with. If you don't care
        about software like I do, then don't try and start a software company.
        Pick a profitable problem you can become obsessed with.
      </p>

      <h2>Lazy Person's Shortcut</h2>
      <p>
        Now that I've given enough advice about how to get started, I'll give
        you a shortcut that I accidentally used. The easiest and fastest way to
        start a startup is to date a successful startup founder. For 3 months
        during my NYC era, I dated a Tech CEO/Founder who had a seed stage
        Education Software startup. Seeing him run his company helped me realize
        it's not that complicated or hard to run a company. He would casually
        drop these one-liners in everyday conversation that would impact my life
        in meaningful ways. For example, when he was telling me the story of how
        he started his company as the sole engineer, he said "It’s crazy how
        much one engineer can do." That really resonated with me because I
        thought "Hey - I'm an engineer. Therefore, I can also accomplish
        everything he has done." That same day, I started coding my personal
        website and re-learning full stack engineering. Being 10 years older
        than me, he would drop these random words of wisdom that still influence
        me today. Even if you're not on the dating market, all I mean to
        illustrate is humans are social creatures and we pick up the behaviors
        of those around us. If you want to be a successful founder, surround
        yourself with other successful founders so you can learn how they think.
        Osmosis into your brain how successful founders think and make
        decisions.
      </p>

      <h2>A Closing Note</h2>
      <p>
        If all this feels overwhelming, then I have not done my job as a writer.
        The purpose of this essay is to help other aspiring founders get started
        and to demystify the startup world. One thing you should know about me
        is that I hate gatekeeping knowledge and opportunity from others. I
        accidentally fell in love with the startup journey and can’t imagine
        working for someone else. Despite doing my fair share of warning people
        how much work it is to be a founder, it made me realize my full
        potential and my values. You have one life - bet on yourself.
      </p>
    </div>
  );
};

export default Startup;
