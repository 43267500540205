const Home = () => (
  <div>
    <h2>Introduction</h2>
    <p>
      Welcome! My name is Zinnie Zhang and I invite you to explore my website. I
      am currently based in Boston, drawing daily inspiration from its
      intellectual atmosphere as a 1L at Harvard Law School. I am originally
      from Fuzhou, China and grew up in the harsh winters of Toronto, Canada.
    </p>
    <p>
      At heart, I am a software engineer who loves to build things. During my
      career at Yext (Marketing SaaS Company), I observed that enterprises were
      paying millions of dollars annually for bad software, so I quit to code
      better marketing software myself. I'm the Founder of an Enterprise
      Software company called <a href="http://www.ent-tor.com">Enttor</a>. If
      you are passionate about social media marketing and have experience with
      MongoDB/React/Next.js, please contact my Enttor email. I am actively
      looking for rockstar Fullstack Software Engineers.
    </p>

    <p>
      In my free time, I enjoy reading biographies, listening to podcasts, going
      to coffee shops, and Soulcycle.
    </p>

    <div>
      <h2>Connect with me:</h2>
      <ul>
        <li>
          <a href="https://www.linkedin.com/in/zinniezhang">LinkedIn</a>
        </li>
        <li>
          <a href="https://www.instagram.com/zinniezhang">Instagram</a>
          <p style={{ display: "inline" }}>
            {" "}
            - a visual diary of my happy moments.{" "}
          </p>
        </li>
        <li>
          <a href="https://www.threads.net/@zinniezhang">Threads</a>
          <p style={{ display: "inline" }}>
            {" "}
            - a casual place where I jot down my random thoughts. If you are
            curious how my brain works, this is the place to be.{" "}
          </p>
        </li>
      </ul>
      <h2>Contact Me:</h2>
      <li>Personal: xzhang@jd27.law.harvard.edu</li>
      <li>Business: zzhang@ent-tor.com</li>
    </div>
  </div>
);

export default Home;
